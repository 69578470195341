import React from "react";
import Google from "../svg/icons/Google";
import {SvgButton} from "./SvgButton";


export const GooglePLay = () => {
    return (
        <a href="https://play.google.com/store/apps/details?id=com.Wildspace.Wildspacegame" target="_blank"
           rel="external">
            <SvgButton>
                <Google/>
            </SvgButton>
        </a>
    )
}