import React, {useState} from "react";
import styled from "styled-components";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import {FormattedMessage, useIntl} from "gatsby-plugin-intl";

const StyledFooter = styled.footer`{
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: rgb(3.2, 1.8, 1.8);
}`

const ImageWrapper = styled.div`{
  width: 100%;
  height: 229px;
  max-width: 2030px;
  position: relative;
  bottom: 0;
}`

const ImgWrapper = styled.div`{
  height: 100%;
  width: 100%;
}`

const LayoutImageWrapperDesktop = styled.div`{
  @media (min-width: 1100px) {
    max-width: 2030px;
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: block;
  }
  display: none;
}`

const LayoutImageWrapperMobile = styled.div`{
  @media (min-width: 1100px) {
    display: none;
  }
  width: 100%;
}`


const RealFooter = styled.div`{
  width: 100%;
  position: absolute;
  height: 50px;
  bottom: 0;
  left: 0;
  background: rgb(3.2, 1.8, 1.8);
}`

const SocialWrapper = styled.div`{
  position: absolute;
  top: 32px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(min-width: 1100px){
    top: 52px;
  }
}`

const StyledContentWrapper = styled.div`{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;

  @media (min-width: 540px) {
    width: 380px;
  }
}`

interface LocalProps {
    localisation: string
}

const StyledHeading = styled.h2<LocalProps>`{
  text-align: center;
  @media (min-width: 540px) {
    font-size: 24px;
    line-height: 27px;
  }
  font-size: 16px;
  line-height: 18px;
  margin: 0;
  color: #FFA116;
  font-weight: 700;
  font-family: ${({localisation}) => (localisation === 'ru') ? "Roboto, sans-serif" : 'Courier Prime Regular,sans-serif'};

}`

const IconsWrapper = styled.div`{
  margin-top: 13px;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (min-width: 540px) {

  }
}`

const IconWrapper = styled.div`{
  cursor: pointer;
  width: 42px;
  position: relative;
  top: 0;
  left: 0;
  @media (min-width: 540px) {
    width: 54px;
  }
}`

const AgreementTextWrapper = styled.div`{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 4000;
  top: -30px;
  left: 0;
  @media(min-width: 350px){
    top: -20px;
  }
  @media(min-width: 400px){
    top: -10px;
  }
  @media(min-width: 470px){
    top: 0;
  }
}`

const AgreementDiv = styled.div`{
  width: 100%;
  max-width: 345px;
  display: block;
  text-align: center;
  @media (min-width: 720px) {
    margin-top: 12px;
    max-width: 1000px;
  }
}`

const AgreementSpan = styled.span<LocalProps>`{
  font-family: ${({localisation}) => (localisation === 'ru') ? "Roboto, sans-serif" : 'Courier Prime Regular,sans-serif'};
  font-weight: 400;
  line-height: 13.48px;
  color: #F4DDA0;
  font-size: 12px;
  height: 14px;
  @media (min-width: 720px) {
    line-height: 17.97px;
    font-size: 18px;
    height: 18px;
  }
}`

const AgreementUnderlineSpan = styled.span<LocalProps>`{
  font-family: ${({localisation}) => (localisation === 'ru') ? "Roboto, sans-serif" : 'Courier Prime Regular,sans-serif'};
  border-bottom: 1px solid #F4DDA0;
  line-height: 13.48px;
  height: 14px;
  :hover {
    cursor: pointer;
  }

  font-weight: 400;
  color: #F4DDA0;
  font-size: 12px;
  @media (min-width: 720px) {
    font-size: 18px;
    line-height: 17.97px;
    height: 19px;
  }
}`


export const Footer = () => {
    const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "footer/footer-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopImage: file(relativePath: { eq: "footer/footer.png" }) {
        childImageSharp {
          fixed(width: 2030, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      vkDefault: file(relativePath: { eq: "icons/vk-default.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      vkHover: file(relativePath: { eq: "icons/vk-hover.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      twitterDefault: file(relativePath: { eq: "icons/twitter-default.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      twitterHover: file(relativePath: { eq: "icons/twitter-hover.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      facebookDefault: file(relativePath: { eq: "icons/facebook-default.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      facebookHover: file(relativePath: { eq: "icons/facebook-hover.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      redditDefault: file(relativePath: { eq: "icons/reddit-default.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      redditHover: file(relativePath: { eq: "icons/reddit-hover.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      instDefault: file(relativePath: { eq: "icons/inst-default.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      instHover: file(relativePath: { eq: "icons/inst-hover.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

    const backMobile = data.mobileImage.childImageSharp.fluid

    const backDesktop = data.desktopImage.childImageSharp.fixed


    const vkIcons = [
        data.vkDefault.childImageSharp.fluid,
        data.vkHover.childImageSharp.fluid
    ]
    const twitterIcons = [
        data.twitterDefault.childImageSharp.fluid,
        data.twitterHover.childImageSharp.fluid
    ]
    const facebookIcons = [
        data.facebookDefault.childImageSharp.fluid,
        data.facebookHover.childImageSharp.fluid
    ]
    const redditIcons = [
        data.redditDefault.childImageSharp.fluid,
        data.redditHover.childImageSharp.fluid
    ]
    const instIcons = [
        data.instDefault.childImageSharp.fluid,
        data.instHover.childImageSharp.fluid
    ]

    const intl = useIntl()
    const [loc, setLoc] = useState(intl.locale)

    const [curVkIcon, setCurVkIcon] = useState(0)
    const [curTwitterIcon, setCurTwitterIcon] = useState(0)
    const [curFacebookIcon, setCurFacebookIcon] = useState(0)
    const [curRedditIcon, setCurRedditIcon] = useState(0)
    const [curInstIcon, setCurInstIcon] = useState(0)

    return (
        <StyledFooter>
            <ImageWrapper>
                <ImgWrapper>
                    <LayoutImageWrapperDesktop>
                        <Img
                            style={{zIndex: -2, width: '100%'}}
                            objectFit="cover"
                            objectPosition="50% 50%"
                            loading={'lazy'} fixed={backDesktop} alt=""
                        />
                    </LayoutImageWrapperDesktop>
                    <LayoutImageWrapperMobile>
                        <Img
                            style={{zIndex: -2}}
                            loading={'lazy'} fluid={backMobile} alt=""
                        />
                    </LayoutImageWrapperMobile>
                </ImgWrapper>
            </ImageWrapper>
            <SocialWrapper>
                <StyledContentWrapper>
                    <StyledHeading localisation={loc}>
                        <FormattedMessage id="footer.stayconnected"/>
                    </StyledHeading>
                    <IconsWrapper>
                        <a href="https://www.instagram.com/wildspace.games/" target="_blank" rel="external">
                            <IconWrapper
                                onMouseOver={() => {
                                    setCurInstIcon(1)
                                }}
                                onMouseOut={() => {
                                    setCurInstIcon(0)
                                }}
                            >
                                <Img
                                    style={{zIndex: -2}}
                                    loading={'lazy'} fluid={instIcons[curInstIcon]} alt=""
                                />
                            </IconWrapper>
                        </a>
                        <a href="https://www.facebook.com/WildSpace.games/" target="_blank" rel="external">
                            <IconWrapper
                                onMouseOver={() => {
                                    setCurFacebookIcon(1)
                                }}
                                onMouseOut={() => {
                                    setCurFacebookIcon(0)
                                }}
                            >
                                <Img
                                    style={{zIndex: -2}}
                                    loading={'lazy'} fluid={facebookIcons[curFacebookIcon]} alt=""
                                />
                            </IconWrapper>
                        </a>
                        <a href="https://twitter.com/wildspacegame" target="_blank" rel="external">
                            <IconWrapper
                                onMouseOver={() => {
                                    setCurTwitterIcon(1)
                                }}
                                onMouseOut={() => {
                                    setCurTwitterIcon(0)
                                }}
                            >
                                <Img
                                    style={{zIndex: -2}}
                                    loading={'lazy'} fluid={twitterIcons[curTwitterIcon]} alt=""
                                />
                            </IconWrapper>
                        </a>
                        <a href="https://www.reddit.com/r/WildSpaceGame/" target="_blank" rel="external">

                            <IconWrapper
                                onMouseOver={() => {
                                    setCurRedditIcon(1)
                                }}
                                onMouseOut={() => {
                                    setCurRedditIcon(0)
                                }}
                            >
                                <Img
                                    style={{zIndex: -2}}
                                    loading={'lazy'} fluid={redditIcons[curRedditIcon]} alt=""
                                />
                            </IconWrapper>
                        </a>
                        <a href="https://vk.com/wildspacegame" target="_blank" rel="external">
                            <IconWrapper
                                onMouseOver={() => {
                                    setCurVkIcon(1)
                                }}
                                onMouseOut={() => {
                                    setCurVkIcon(0)
                                }}
                            >
                                <Img
                                    style={{zIndex: -2}}
                                    loading={'lazy'} fluid={vkIcons[curVkIcon]} alt=""
                                />
                            </IconWrapper>
                        </a>
                    </IconsWrapper>
                </StyledContentWrapper>
            </SocialWrapper>
            <RealFooter>
                <AgreementTextWrapper>
                    <AgreementDiv>
                        <a href={intl.locale == "ru" ? "/files/ru_eula_wildspace.html" : "/files/en_eula_wildspace.html"} target="_blank" rel="external">
                            <AgreementUnderlineSpan localisation={loc}>
                                <FormattedMessage id='footer.privacy'/>
                            </AgreementUnderlineSpan>
                        </a>
                        <AgreementSpan localisation={loc}>
                            <FormattedMessage id='footer.and'/>
                        </AgreementSpan>
                        <a href={intl.locale == "ru" ? "/files/ru_eula_wildspace.html" : "/files/en_eula_wildspace.html"} target="_blank" rel="external">
                            <AgreementUnderlineSpan localisation={loc}>
                                <FormattedMessage id='footer.user'/>
                            </AgreementUnderlineSpan>
                        </a>
                        <AgreementSpan localisation={loc}>
                            <FormattedMessage id='footer.wild'/>
                        </AgreementSpan>
                    </AgreementDiv>
                </AgreementTextWrapper>
            </RealFooter>
        </StyledFooter>
    )
}