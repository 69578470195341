import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import {HeroAdverting} from "../adverting/hero-adverting/HeroAdverting";
// @ts-ignore
import Slider from "react-slick"
import {HeroesText} from "./heroes-text/HeroesText";
import {any, string} from "prop-types";
// @ts-ignore
import {useWidth} from '../../hooks/useWidth';
// @ts-ignore
import {Box} from "@chakra-ui/react"
import {useIntl} from "gatsby-plugin-intl";
// @ts-ignore
import Engineer from "../../svg/engineer.svg" ;
// @ts-ignore
import Scientist from "../../svg/scince.svg" ;
// @ts-ignore
import Navigator from "../../svg/nav.svg" ;
// @ts-ignore
import Military from "../../svg/mili.svg" ;
// @ts-ignore
import Spy from "../../svg/spy.svg" ;


interface itemProps {
    ind: any
}

const Stars = styled.div`{
  position: relative;
  top: -10px;
  left: -8px;
  display: flex;
  margin: 1px;
  padding-left: 5px;
  justify-content: left;
  @media (min-width: 1000px) {
    position: relative;
    top: -12px;
  }
}`


const StarWrapper = styled.div`{
  width: 16px;
  height: 16px;
  margin: 1.5px;
  @media(min-width: 1000px){
    width: 20px;
    height: 20px;
  }
}`


const SliderItemWrapper = styled.div`{
  width: 100px;
  position: relative;
  top: 0;
  left: 0;
  transition: all 0.2s;
}`


const SliderItem = styled.div<itemProps>`{
  z-index: ${props => props.ind};
  width: 100%;
  position: absolute;
  top: 10%;
  left: ${({ind}) => (ind === 0) ? '0' : '-25%'};
}`


const PanelWrapperMobile = styled.div`{
  display: block;
  width: 100%;
  position: absolute;
  bottom: 5%;
  left: 0;
  z-index: 10000;
  @media (min-width: 1000px) {
    display: none;
  }
}`

const PanelDesktopWrapper = styled.div`{
  display: none;
  @media (min-width: 1000px) {
    z-index: 10000;
    bottom: 5%;
    left: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
  }

}`

const PanelWrapperDesktop = styled.div`{
  z-index: 10000;
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 2050px;
  animation: slide 10s infinite;
  
  @keyframes slide {
    
  }
}`


const HeroesWrapper = styled.section`{
  width: 100%;
  z-index: 80;
  padding-top: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(3.2, 1.8, 1.8);
}`

const HeroesWrapperLayout = styled.div`{
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  position: relative;
  top: 0;
  left: 0;
  @media (min-width: 720px) {
    justify-content: center;
  }
}`


const HeroesContent = styled.div`{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 80;
  width: 100%;
  top: 0;
  left: 0;
}`

const LayerWrapper = styled.div`{
  width: 100%;
  padding-right: 16px;
  position: relative;
  margin: auto;
  @media (min-width: 1100px) {
    max-width: 1480px;
    padding-right: 0;
  }
}`

const LayerContentWrapper = styled.div`{
  position: absolute;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 25;
  width: 100%;
  top: 0;
  left: 0;
}`

const HeroWrapper = styled.div`{
  top: 40%;
  left: 4%;
  position: absolute;
  z-index: 2000;
  width: 260px;
  @media (min-width: 350px) {
    width: 300px;
    top: 35%;
  }
  @media (min-width: 380px) {
    width: 310px;
  }
  @media (min-width: 345px) {
    left: 6%;
  }
  @media (min-width: 360px) {
    left: 5%;
  }
  @media (min-width: 370px) {
    width: 310px;
    left: 5%;
    top: 32%;
  }
  @media (min-width: 390px) {
    left: 8%;
  }

  @media (min-width: 1000px) {
    width: 550px;
    left: 47%;
    top: -15%;
  }
  @media (min-width: 1400px) {
    width: 650px;
    left: 47%;
    top: -18%;
  }
}`


const CircleWrapper = styled.div`{
  bottom: 25%;
  left: 4%;
  position: absolute;
  z-index: 200;
  width: 280px;
  @media (min-width: 345px) {
    left: 6%;
  }
  @media (min-width: 360px) {
    width: 336px;
    left: 0;
  }
  @media (min-width: 370px) {
    left: 3%;
  }
  @media (min-width: 390px) {
    left: 5%;
  }
  @media (min-width: 1000px) {
    left: 55%;
    bottom: 35%;
  }
  @media (min-width: 1400px) {
    width: 400px;
  }
}`


const Text = styled.div`{
  position: relative;
  padding: 5px;
  width: 263px;
  top: 32px;
  @media (min-width: 1000px) {
    width: 380px;
    padding: 2px;
    left: -10%;
    position: relative;
    top: 25%;
    margin-top: 50px;
  }
}`


const TextHeader = styled.div`{
  width: 100%;
  display: flex;
  justify-content: left;
}`

const TextWrapper = styled.div`{
  width: 100%;

}`

interface LocalProps {
    localisation: string
}

const TextParagraph = styled.div<LocalProps>`{
  position: relative;
  font-size: 16px;
  font-family: ${({localisation}) => (localisation === 'ru') ? "Roboto, sans-serif" : 'Courier Prime Regular,sans-serif'};
  color: #F4DDA0;
}`

const TextParagraphItalic = styled.div<LocalProps>`{
  position: relative;
  font-style: italic;
  font-size: 16px;
  margin-bottom: 7px;
  font-family: ${({localisation}) => (localisation === 'ru') ? "Roboto, sans-serif" : 'Courier Prime Italic ,sans-serif'};
  color: #F4DDA0;
  @media (min-width: 1100px) {
    font-size: 18px;
    top: -20px;
  }
}`

const TextBoldParagraph = styled.div<LocalProps>`{
  position: relative;
  font-size: 16px;
  font-family: ${({localisation}) => (localisation === 'ru') ? "Roboto, sans-serif" : 'Courier Prime Regular,sans-serif'};
  font-weight: 700;
  color: #F4DDA0;
  display: none;
  @media (min-width: 440px) {
    display: block;
  }
  @media (min-width: 1100px) {
    font-size: 20px;
    top: -20px;
  }
}`

const TextName = styled.div<LocalProps>`{
  position: relative;
  top: -8px;
  font-size: 20px;
  font-family: ${({localisation}) => (localisation === 'ru') ? "Roboto, sans-serif" : 'Courier Prime Regular,sans-serif'};
  font-weight: 700;
  color: #F4DDA0;
  @media (min-width: 540px) {
    font-size: 24px;
  }
  @media (min-width: 800px) {
    font-size: 24px;
  }
}`

const IconWrapper = styled.div`{
  width: 57px;
  position: relative;
  z-index: 2000;

  @media (min-width: 720px) {
    width: 54px;
  }
  @media (min-width: 800px) {
    width: 54px;
  }
}`

const TextBody = styled.div`{
  margin-top: 16px;
  @media (min-width: 540px) {
    margin-top: 40px;
  }

  @media (min-width: 800px) {
    margin-top: 16px;
  }
}`

const AdditionalHeaderWrapper = styled.div`{
  margin-left: 14px;
  @media (min-width: 540px) {
    margin-left: 16px;
  }
  @media (min-width: 800px) {
    margin-left: 14px;
  }
}`


const BtnWrapperLeft = styled.div`{
  position: absolute;
  z-index: 5000;
  width: 33px;
  top: 50%;
  left: 7%;

  @media (min-width: 350px) {
    top: 50%;
    left: 7%;
  }

  @media (min-width: 440px) {
    width: 48px;
    top: 30%;
    left: 15%;
  }
  @media (min-width: 1300px) {
    width: 48px;
    top: 30%;
    left: 19%;
  }
  @media (min-width: 1300px) {
    width: 48px;
    top: 30%;
    left: 21%;
  }
}`

const BtnWrapperRight = styled.div`{
  position: absolute;
  z-index: 5000;
  top: 50%;
  right: 12%;
  width: 33px;

  @media (min-width: 350px) {
    top: 50%;
    right: 12%;
  }
  @media (min-width: 440px) {
    width: 48px;
    top: 30%;
    right: 7%;
  }
  @media (min-width: 440px) {
    width: 48px;
    top: 30%;
    right: 10%;
  }
  @media (min-width: 1300px) {
    width: 48px;
    top: 30%;
    right: 12%;
  }
}`


const BackImgMobile = styled.div`{
  @media (min-width: 1100px) {
    display: none;
  }
  width: 100%;
}`

const BackImgDesktop = styled.div`{
  @media (min-width: 1100px) {
    max-width: 1480px;
    width: 100%;
    overflow: hidden;
    height: 100%;
    display: block;
  }
  display: none;
}`

export const Heroes = () => {


    const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "heroes/heroes-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 4080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopImage: file(relativePath: { eq: "heroes/heroes.png" }) {
        childImageSharp {
          fixed(width: 1480, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      mobilePanelka: file(relativePath: { eq: "heroes/mobile-panelka.png" }) {
        childImageSharp {
          fluid(maxWidth: 4080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopPanelka: file(relativePath: { eq: "heroes/panelka.png" }) {
        childImageSharp {
          fixed(width: 2040, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      mobileHero: file(relativePath: { eq: "heroes/engineer/lisa-ward.png" }) {
        childImageSharp {
          fluid(maxWidth: 4080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopHero: file(relativePath: { eq: "heroes/engineer/lisa-ward.png" }) {
        childImageSharp {
          fluid(maxWidth: 4080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      circleAsset: file(relativePath: { eq: "heroes/circle.png" }) {
        childImageSharp {
          fluid(maxWidth: 4080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      btnLeft: file(relativePath: { eq: "heroes/btn/btn-left.png" }) {
        childImageSharp {
          fluid(maxWidth: 4080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      btnLeftHover: file(relativePath: { eq: "heroes/btn/btn-left-hover.png" }) {
        childImageSharp {
          fluid(maxWidth: 4080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      btnRight: file(relativePath: { eq: "heroes/btn/btn-right.png" }) {
        childImageSharp {
          fluid(maxWidth: 4080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      btnRightHover: file(relativePath: { eq: "heroes/btn/btn-right-hover.png" }) {
        childImageSharp {
          fluid(maxWidth: 4080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      starIcon: file(relativePath: { eq: "heroes/hero-star.png" }) {
        childImageSharp {
          fluid(maxWidth: 4080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      icons: allFile(filter: { relativeDirectory: { eq: "heroes/icon" } }) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              base64
                aspectRatio
                src
                srcSet
                sizes
            }
          }
        }
      }
     }
      models: allFile(filter: { relativeDirectory: { eq: "heroes/model" } }) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              base64
                aspectRatio
                src
                srcSet
                sizes
            }
          }
        }
      }
    }
}`)


    const desktopPanelka = data.desktopPanelka.childImageSharp.fixed

    const mobilePanelka = data.mobilePanelka.childImageSharp.fluid

    const iconStar = data.starIcon.childImageSharp.fluid

    const leftBtn = [
        data.btnLeft.childImageSharp.fluid,
        data.btnLeftHover.childImageSharp.fluid,
    ]

    const rightBtn = [
        data.btnRight.childImageSharp.fluid,
        data.btnRightHover.childImageSharp.fluid,
    ]

    const circle = [
        data.circleAsset.childImageSharp.fluid,
    ]

    const backMobile = data.mobileImage.childImageSharp.fluid

    const backDesktop = data.desktopImage.childImageSharp.fixed

    const m = []
    m.push(data.icons)
    const l = []
    l.push(data.models)

    let dataHeroIcons = []
    for (let icon in m[0].edges) {
        let data = {
            heroIcon: m[0].edges[icon].node,
        };
        dataHeroIcons.push(data)
    }
    // @ts-ignore
    let dataHeroImages = []
    for (let model in l[0].edges) {
        let data = {
            heroImage: l[0].edges[model].node,
        };
        dataHeroImages.push(data)
    }

    const intl = useIntl();
    const [locMy, setLocMy] = useState(intl.locale)

    const [activeIndex, setActiveIndex] = useState(11);
    let loc = dataHeroImages[activeIndex].heroImage.base
    const [chosenHero, setChosenHero] = useState(dataHeroImages[activeIndex].heroImage.childImageSharp.fluid)
    const [myId, setMyId] = useState(intl.formatMessage({id: `${loc}.myid`}))
    const [starCount, setStarCount] = useState(intl.formatMessage({id: `${loc}.stars`}))
    const [type, setType] = useState(intl.formatMessage({id: `${loc}.type`}))
    const [name, setName] = useState(intl.formatMessage({id: `${loc}.name`}))
    const [skill, setSkill] = useState(intl.formatMessage({id: `${loc}.perk_description`}))
    const [desc, setDesc] = useState(intl.formatMessage({id: `${loc}.lore`}))


    const [leftButton, setLeftButton] = useState(0)
    const [rightButton, setRightButton] = useState(0)

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setActiveIndex((current) => {
    //             const res = current === dataHeroIcons.length - 1 ? 0 : current + 1
    //             return res
    //         })
    //     }, 3000)
    //     // @ts-ignore
    //     return () => clearInterval()
    // }, [])

    const inc = () => {
        let i = activeIndex
        if (i === 24) {
            setActiveIndex(0)
            i = activeIndex;
            // @ts-ignore
            let loc = dataHeroImages[i].heroImage.base
            // @ts-ignore
            setChosenHero(dataHeroImages[i].heroImage.childImageSharp.fluid)
            // @ts-ignore
            setMyId((intl.formatMessage({id: `${loc}.myid`})))

            setStarCount(intl.formatMessage({id: `${loc}.stars`}))
            setType(intl.formatMessage({id: `${loc}.type`}))
            setName(intl.formatMessage({id: `${loc}.name`}))
            setSkill(intl.formatMessage({id: `${loc}.perk_description`}))
            setDesc(intl.formatMessage({id: `${loc}.lore`}))
        } else {
            i++
            setActiveIndex(i)
            // @ts-ignore
            let loc = dataHeroImages[i].heroImage.base
            // @ts-ignore
            setChosenHero(dataHeroImages[i].heroImage.childImageSharp.fluid)

            // @ts-ignore
            // @ts-ignore
            setMyId((intl.formatMessage({id: `${loc}.myid`})))

            setStarCount(intl.formatMessage({id: `${loc}.stars`}))
            setType(intl.formatMessage({id: `${loc}.type`}))
            setName(intl.formatMessage({id: `${loc}.name`}))
            setSkill(intl.formatMessage({id: `${loc}.perk_description`}))
            setDesc(intl.formatMessage({id: `${loc}.lore`}))
            // @ts-ignore
            setChosenHero(dataHeroImages[i].heroImage.childImageSharp.fluid)
        }
    }

    const dec = () => {
        let i = activeIndex
        if (i === 0) {
            setActiveIndex(24)
            i = activeIndex;
            // @ts-ignore
            let loc = dataHeroImages[i].heroImage.base
            // @ts-ignore
            setChosenHero(dataHeroImages[i].heroImage.childImageSharp.fluid)
            // @ts-ignore
            setMyId((intl.formatMessage({id: `${loc}.myid`})))

            setStarCount(intl.formatMessage({id: `${loc}.stars`}))
            setType(intl.formatMessage({id: `${loc}.type`}))
            setName(intl.formatMessage({id: `${loc}.name`}))
            setSkill(intl.formatMessage({id: `${loc}.perk_description`}))
            setDesc(intl.formatMessage({id: `${loc}.lore`}))
        } else {
            i--
            setActiveIndex(i)
            // @ts-ignore
            let loc = dataHeroImages[i].heroImage.base
            // @ts-ignore
            setChosenHero(dataHeroImages[i].heroImage.childImageSharp.fluid)
            // @ts-ignore
            setMyId((intl.formatMessage({id: `${loc}.myid`})))

            setStarCount(intl.formatMessage({id: `${loc}.stars`}))
            setType(intl.formatMessage({id: `${loc}.type`}))
            setName(intl.formatMessage({id: `${loc}.name`}))
            setSkill(intl.formatMessage({id: `${loc}.perk_description`}))
            setDesc(intl.formatMessage({id: `${loc}.lore`}))
            // @ts-ignore
            setChosenHero(dataHeroImages[i].heroImage.childImageSharp.fluid)
        }
    }

    let icon
    if (myId == '1') {
        icon = <Engineer/>
    }
    if (myId == '2') {
        icon = <Scientist/>
    }
    if (myId == '3') {
        icon = <Navigator/>
    }
    if (myId == '4') {
        icon = <Military/>
    }
    if (myId == '5') {
        icon = <Spy/>
    }

    let arr = [];

    for (let i = 0; i < Number(starCount); i++) {
        let jsx = <Img
            style={{zIndex: -2}}
            loading={'lazy'} fluid={iconStar} alt=" "
        />
        arr.push(jsx)
    }
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return (
        <HeroesWrapper>
            <HeroesWrapperLayout>
                <HeroesContent>
                    <HeroesText/>
                    <LayerWrapper>
                        <BtnWrapperLeft
                            onMouseOver={() => {
                                setLeftButton(1)
                            }}
                            onMouseOut={() => {
                                setLeftButton(0)
                            }}
                            onClick={() => dec()}
                        >
                            <Img
                                style={{zIndex: 300}}
                                loading={'lazy'} fluid={leftBtn[leftButton]} alt=" "
                            />
                        </BtnWrapperLeft>
                        <BtnWrapperRight
                            onMouseOver={() => {
                                setRightButton(1)
                            }}
                            onMouseOut={() => {
                                setRightButton(0)
                            }}
                            onClick={() => inc()}
                        >
                            <Img
                                style={{zIndex: 300}}
                                loading={'lazy'} fluid={rightBtn[rightButton]} alt=" "
                            />
                        </BtnWrapperRight>
                        <BackImgDesktop>
                            <Img
                                style={{zIndex: -2, width: '100%'}}
                                objectFit="cover"
                                objectPosition="50% 50%"
                                loading={'lazy'} fixed={backDesktop} alt=""
                            />
                        </BackImgDesktop>
                        <BackImgMobile>
                            <Img
                                style={{zIndex: -2}}
                                loading={'lazy'} fluid={backMobile} alt=""
                            />
                        </BackImgMobile>
                        <LayerContentWrapper>
                            <Text>
                                <TextHeader>
                                    <IconWrapper>
                                        {icon}
                                    </IconWrapper>
                                    <AdditionalHeaderWrapper>
                                        <TextParagraph localisation={locMy}>
                                            {type}
                                        </TextParagraph>
                                        <TextName localisation={locMy}>
                                            {name}
                                        </TextName>
                                        <Stars>
                                            {arr.map((icon: any, index: any) => (
                                                <StarWrapper key={index}>
                                                    {icon}
                                                </StarWrapper>
                                            ))}
                                        </Stars>
                                    </AdditionalHeaderWrapper>
                                </TextHeader>
                                <TextBody>
                                    <TextWrapper>
                                        <TextParagraphItalic localisation={locMy}>
                                            {skill}
                                        </TextParagraphItalic>
                                    </TextWrapper>
                                    <TextWrapper>
                                        <TextBoldParagraph localisation={locMy}>
                                            {desc}
                                        </TextBoldParagraph>
                                    </TextWrapper>
                                </TextBody>
                            </Text>
                        </LayerContentWrapper>
                        <HeroWrapper>
                            <Img
                                style={{zIndex: 700}}
                                loading={'lazy'} fluid={chosenHero} alt=" "
                            />
                        </HeroWrapper>
                        <CircleWrapper>
                            <Img
                                style={{zIndex: 300}}
                                loading={'lazy'} fluid={circle} alt=" "
                            />
                        </CircleWrapper>
                    </LayerWrapper>
                    <PanelWrapperMobile>
                        <Img
                            style={{zIndex: 300}}
                            loading={'lazy'} fluid={mobilePanelka} alt=" "
                        />
                    </PanelWrapperMobile>
                    <PanelDesktopWrapper>
                        <PanelWrapperDesktop>
                            <Img
                                style={{zIndex: -2, width: '100%'}}
                                objectFit="cover"
                                objectPosition="50% 50%"
                                loading={'lazy'} fixed={desktopPanelka} alt="Hungar"
                            />
                        </PanelWrapperDesktop>
                    </PanelDesktopWrapper>
                </HeroesContent>
            </HeroesWrapperLayout>
            <HeroAdverting/>
        </HeroesWrapper>
    )
}