import React, {ReactNode} from 'react';
import styled from "styled-components"


const StyledContainer = styled.main`{
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  transition: max-width 0.5s;
  flex-direction: column;
}`

export interface ContainerProps
    extends React.HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
}

export const Container = ({children}: ContainerProps) => {
    return <StyledContainer>{children}</StyledContainer>;
};