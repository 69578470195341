import React, {ReactNode} from "react";
import {Container} from "./container/Container";
import styled from "styled-components";
import {Header} from "./header/Header";
import {Footer} from "./footer/Footer";

const StyledLayout = styled.div`{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
}`


export interface LayoutProps
    extends React.HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
}

export const Layout = ({children}: LayoutProps) => {
    return (
        <StyledLayout>
            <Header/>
            <Container>
                {children}
            </Container>
            <Footer/>
        </StyledLayout>
    );
};