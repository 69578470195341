import {StaticImage} from "gatsby-plugin-image";
import Img from "gatsby-image";
import React, {useState} from "react";
import styled from "styled-components";
import {graphql, useStaticQuery} from "gatsby";
import { useIntl, Link, FormattedMessage} from 'gatsby-plugin-intl';


const HeroWrapper = styled.div`{
  width: 145px;
  position: absolute;
  z-index: 200;
  bottom: -25%;
  right: -35%;
  @media (min-width: 380px) {
    width: 165px;
  }
  @media (min-width: 720px) {
    width: 180px;
  }
  @media (min-width: 1000px) {
    width: 250px;
  }
}`


const UnionWrapper = styled.div`{
  position: absolute;
  z-index: 1000;
  top: -15%;
  left: 71%;
  width: 100px;
  @media (min-width: 1000px) {
    top: 50%;
    left: 90%;
    width: 175px;
  }
}`

const ParagraphWrapper = styled.div`{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  line-height: 15.72px;
  z-index: 400;
  width: 100px;
  height: 46px;
  text-align: center;
  @media (min-width: 1000px) {
    top: 20px;
    width: 175px;
    height: 79px;
    line-height: 22.46px;
  }
}`

interface LocalProps {
    localisation: string
}

const StyledParagraph = styled.p<LocalProps>`{
  margin: 0;
  text-align: center;
  font-size: 14px;
  color: #3B3120;
  font-weight: 700;
  line-height: 15.72px;
  font-family: ${({ localisation }) => (localisation === 'ru') ? "Roboto, sans-serif" : 'Courier Prime Regular,sans-serif'};
  @media (min-width: 1000px) {
    line-height: 22.46px;
    font-size: 20px;
  }
}`


export const Hero = () => {
    const data = useStaticQuery(graphql`
    query {
      mobileUnionImage: file(relativePath: { eq: "gameplay/union/union-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 4080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopUnionImage: file(relativePath: { eq: "gameplay/union/union.png" }) {
        childImageSharp {
          fluid(maxWidth: 4080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

    const unionImages = [
        data.mobileUnionImage.childImageSharp.fluid,
        {
            ...data.desktopUnionImage.childImageSharp.fluid,
            media: `(min-width: 1000px)`,
        },
    ]

    const intl = useIntl()

    const [loc, setLoc] = useState(intl.locale)

    return (
        <HeroWrapper>
            <StaticImage
                style={{zIndex: -2}}
                placeholder={'blurred'}
                src={`../../../images/gameplay/hero/gameplay-hero.png`}
                alt={'Hero'}
                formats={['auto', 'webp', 'avif']}
                quality={100}

            >
            </StaticImage>
            <UnionWrapper>
                <Img
                    loading={'lazy'} fluid={unionImages} alt=" "
                />
                <ParagraphWrapper>
                    <StyledParagraph localisation={loc}>
                        <FormattedMessage id='story.watchthe'/>
                        <br/>
                        <FormattedMessage id='story.gameplay'/>
                    </StyledParagraph>
                </ParagraphWrapper>
            </UnionWrapper>
        </HeroWrapper>
    )
}