import {Adverting} from "../Adverting";
import React from "react";
import styled from "styled-components";
import {FormattedMessage} from "gatsby-plugin-intl";


const AdvertingLayout = styled.div`{
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 400px;
  top: 0;
  left: 0;
  @media(min-width: 720px){
    height: 450px;
  }
}`

const AdvertingText = styled.h2`{
  color: #FFA116;
  width: 100%;
  max-width: 360px;
  text-align: center;
  padding: 10px;
  font-weight: 900;
  font-family: 'Fira Sans', sans-serif;
  @media(min-width: 1000px){
    max-width: 1600px;
    font-size: 44px;
    margin-bottom: 18px;
  }
}`

const WildSpaceText = styled.span`{
  color: #00D1FF;
}`

export const HeroAdverting = () => {
    return (
        <AdvertingLayout>
            <AdvertingText>
                <FormattedMessage id="footer.downloadandplay"/>
                <WildSpaceText>
                    <FormattedMessage id="footer.wildspace"/>
                </WildSpaceText>
                <FormattedMessage id="footer.now"/>
            </AdvertingText>
            <Adverting/>
        </AdvertingLayout>
    )
}