import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import {ShipButton} from "./buttons/ShipButton";
// @ts-ignore
import Slider from "react-slick"
import {Arrow} from "./buttons/Arrow";
// @ts-ignore
import ArrowLeft from "../../svg/arrowleft.svg" ;
// @ts-ignore
import ArrowRight from "../../svg/arrowright.svg" ;
import {ShipStroke} from "../../assets/svg/ShipStroke";
import './shipStyles.css'
import {FormattedMessage, useIntl} from "gatsby-plugin-intl";


const ShipsWrapper = styled.section`{
  width: 100%;
  z-index: 1000;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(3.2, 1.8, 1.8);
  @media(min-width: 1000px){
    height: 836px;
  }
  @media(min-width: 2070px){
    height: 836px;
  }
}`

const ShipsContent = styled.div`{
  position: relative;
  margin: 0 auto;
  z-index: 80;
  width: 100%;
  top: 100px;
  left: 0;
  @media(min-width: 1000px){
    width: 2040px;
  }
}`

const ImageWrapperDesktop = styled.div`{
  display: none;
  @media(min-width: 1000px){
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 836px;
  }
}`

const ImageWrapperMobile = styled.div`{
  display: block;
  width: 100%;
  @media(min-width: 1000px){
    display: none;
    width: 100%;
  }
}`

const ShipsContentWrapper = styled.div`{
  position: absolute;
  width: 100%;
  top: 13%;
  left: 0;
  
  @media(min-width: 1000px){
    top: 25%;
    left: 0;
  }
}`

const PanelAndTexts = styled.div`{
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: left;
  width: 100%;
  padding: 10px;
  
  @media(min-width: 1000px){
    width: 304px;
    padding: 0;
    left: 10%;
  }

  @media(min-width: 1300px){
    width: 304px;
    padding: 0;
    left: 15%;
  }
  
  @media(min-width: 1800px){
    width: 304px;
    padding: 0;
    left: 20%;
  }
  
}`

const ShipsPanel = styled.div`{
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1000px) {
    width: 304px;
  }
}`

const ShipPanelWrapper = styled.div`{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (min-width: 1000px) {
    flex-direction: column;
    position: relative;
  }
}`

const PanelItemWrapper = styled.div`{
  z-index: 1000;
  @media (min-width: 1000px) {
    margin-bottom: 10px;
    z-index: 1000;
  }
}`

const MainShip = styled.div`{
  width: 300px;
  position: relative;
  top: 10%;
  left: 0;
  z-index: 200;
  @media (min-width: 1100px) {
    width: 600px;
    top: 0;
    z-index: 200;
  }
}`


const MainShipWrapper = styled.div`{
  width: 100%;
  position: absolute;
  top: 68%;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media(min-width: 1100px){
    top: -35%;
    width: 620px;
    left: 42%;
  }
  @media(min-width: 1400px){
    left: 48%;
  }
}`

const StyledSlider = styled(Slider)`{

  .slick-slider {
    position: relative;
    height: 61px;
    @media (min-width: 1000px) {
      height: 100px;
    }

  }

  //
  .slick-list {
    @media (min-width: 1000px) {
      height: 100px;
    }
    width: 100%;
    height: 61px;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    linear-gradient(270deg, rgba(217, 217, 217, 0) 0%, #D9D9D9 5.04%, #D9D9D9 49.9%, #D9D9D9 94.96%, rgba(217, 217, 217, 0) 100%);
  }

  .slick-track {
    justify-content: space-between;
    width: 100%;
    display: flex;
    position: relative;
    left: 44px;
    @media (min-width: 350px) {
      left: 50px;
    }
    @media (min-width: 1000px) {
      left: 67px;
    }
  }

  .slick-track:after {
    clear: both;
  }

  .slick-slide {
    :hover {
      background: radial-gradient(48.87% 66.12% at 63.87% 50.88%, rgba(0, 209, 255, 0) 0%, rgba(0, 209, 255, 0.252) 100%);
    }
    position: relative;
    border: 1px solid #00D1FF;
    height: 56px;
    margin: 2px;
    @media (min-width: 1000px) {
      height: 82px;
    }
  }
}`

const SliderWrapper = styled.div`{
  position: absolute;
  top: 85%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 400;
}`

const SliderWrapperContent = styled.div`{
  position: relative;
  top: 0;
  left: 0;
  width: 260px;

  @media (min-width: 350px) {
    width: 300px;
  }

  @media (min-width: 1000px) {
    width: 400px;
    height: 80px;
    top: 0;
    left: 0;
  }
}`

const SliderItem = styled.div`{
  z-index: 200;
  width: 100%;
  position: absolute;
  top: -30%;
  left: 0;
  height: 72px;
  @media (min-width: 1000px) {
    height: 104px;
  }
}`

const LeftArrowItem = styled.div`{
  position: absolute;
  z-index: 1000;
  top: 0px;
  left: -22px;

  @media (min-width: 1000px) {
    top: -12px;
    left: -35px;
  }
}`


const SliderMask = styled.div`{
  width: 260px;
  height: 65px;
  top: -2px;
  left: 0;
  @media (min-width: 350px) {
    width: 300px;
    height: 65px;
  }

  @media (min-width: 1000px) {
    top: -2px;
    width: 400px;
    height: 85px;
    left: 0;
  }
  z-index: 100;
  position: absolute;
  mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 4.68%, #FFFFFF 95.79%, rgba(255, 255, 255, 0) 100%);
}`

const RightArrowItem = styled.div`{

  position: absolute;
  top: 0px;
  left: 260px;
  z-index: 1000;
  @media (min-width: 350px) {
    top: 0px;
    left: 300px;
  }
  @media (min-width: 1000px) {
    top: -12px;
    left: 400px;
  }
}`

const TextWrapper = styled.div`{
  width: 100%;
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  top: 0;
  left: 0;
  @media (min-width: 1000px) {
    margin-top: 0;
    width: 304px;
  }
}`

const Text = styled.div`{
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}`

const TextHeading = styled.h2`{
  margin: 0;
  width: 100%;
  color: #FFA116;
  font-weight: 700;
  font-size: 32px;
  font-family: "Fira Sans", sans-serif;
  @media (min-width: 720px) {
    font-size: 48px;
  }
}`

interface LocalProps {
    localisation: string
}

const TextParagraph = styled.p<LocalProps>`{
  margin-bottom: 27px;
  color: #F4DDA0;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  margin-top: 13px;
  font-family: "Fira Sans",sans-serif;
  @media (min-width: 1000px) {
    margin-top: 13px;
    font-size: 16px;
    line-height: 19.2px
  }
}`

const ShipStrokeWrapper = styled.div`{
  width: 100%;
  margin: 0;
  padding: 0;
}`

const FakeSliderWrapper = styled.div`{
  position: relative;
  display: flex;
  justify-content: center;
  width: 261px;
  @media (min-width: 350px) {
    width: 300px;
  }
  @media (min-width: 1000px) {
    width: 402px;
  }
}`

const FakeSliderItem = styled.div`{
  position: relative;
  width: 83px;
  height: 56px;
  border: 1px solid #00D1FF;
  margin: 2px;
  :hover {
    background: radial-gradient(48.87% 66.12% at 63.87% 50.88%, rgba(0, 209, 255, 0) 0%, rgba(0, 209, 255, 0.252) 100%);
  }
  @media (min-width: 350px) {
    width: 96px;
  }
  @media (min-width: 1000px) {
    width: 130px;
    height: 82px;
  }
}`

const FakeSliderImageWrapper = styled.div`{
  width: 100%;
  position: absolute;
  top: -30%;
  left: 0;
  @media (min-width: 350px) {
    top: -40%;
  }
  @media (min-width: 1000px) {
    top: -33%;
  }
}`

const FakeLeftArrowItem = styled.div`{
  position: absolute;
  z-index: 1000;
  top: 0;
  left: -22px;

  @media (min-width: 1000px) {
    top: -12px;
    left: -35px;
  }
}`

const FakeRightArrowItem = styled.div`{

  position: absolute;
  top: 0px;
  left: 260px;
  z-index: 1000;
  @media (min-width: 350px) {
    top: 0px;
    left: 300px;
  }
  @media (min-width: 1000px) {
    top: -12px;
    left: 400px;
  }
}`


export const Ships = () => {
    const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "ships/hungar/hungar-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 16000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopImage: file(relativePath: { eq: "ships/hungar/hungar.png" }) {
        childImageSharp {
          fixed(width: 2040, height: 804, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      
      
      kin1: file(relativePath: { eq: "ships/kinetic/kinetic1.png" }) {
        childImageSharp {
          fluid(maxWidth: 16080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kin2: file(relativePath: { eq: "ships/kinetic/kinetic2.png" }) {
        childImageSharp {
          fluid(maxWidth: 16080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kin3: file(relativePath: { eq: "ships/kinetic/kinetic3.png" }) {
        childImageSharp {
          fluid(maxWidth: 16080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kin4: file(relativePath: { eq: "ships/kinetic/kinetic4.png" }) {
        childImageSharp {
          fluid(maxWidth: 16080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      las1: file(relativePath: { eq: "ships/laser/laser1.png" }) {
        childImageSharp {
          fluid(maxWidth: 16080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      las2: file(relativePath: { eq: "ships/laser/laser2.png" }) {
        childImageSharp {
          fluid(maxWidth: 16080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pls: file(relativePath: { eq: "ships/plasma/plasma1.png" }) {
        childImageSharp {
          fluid(maxWidth: 16080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

    const srcImgDesktop = data.desktopImage.childImageSharp.fixed
    const srcImgMobile = data.mobileImage.childImageSharp.fluid


    const kineticShips = [
        data.kin4.childImageSharp.fluid,
        data.kin2.childImageSharp.fluid,
        data.kin1.childImageSharp.fluid,
        data.kin3.childImageSharp.fluid,
    ]
    const laserShips = [
        data.las1.childImageSharp.fluid,
        data.las2.childImageSharp.fluid,
    ]

    const plasmaShips = [
        data.pls.childImageSharp.fluid,
    ]


    const [activeKinetic, setActiveKinetic] = useState(true)
    const [activeLaser, setActiveLaser] = useState(false)
    const [activePlasma, setActivePlasma] = useState(false)

    const [sliderShips, setSliderShips] = useState(kineticShips)

    const [chosenShipIndex, setChosenShipIndex] = useState(0)

    const activeK = () => {
        if (!activeKinetic) {
            setSliderShips(kineticShips)
            setActiveKinetic(true)
            setActivePlasma(false)
            setActiveLaser(false)
            setChosenShipIndex(0)
        }
    }

    const activeL = () => {
        if (!activeLaser) {
            setSliderShips(laserShips)
            setActiveKinetic(false)
            setActivePlasma(false)
            setActiveLaser(true)
            setChosenShipIndex(0)
        }
    }

    const activeP = () => {
        if (!activePlasma) {
            setSliderShips(plasmaShips)
            setActiveKinetic(false)
            setActivePlasma(true)
            setActiveLaser(false)
            setChosenShipIndex(0)
        }
    }

    const settings = {
        dots: false,
        autoplay: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        draggable: true,
        initialSlide: 0,
        swipe: true,
        nextArrow: <RightArrowItem><Arrow><ArrowRight/></Arrow></RightArrowItem>,
        prevArrow: <LeftArrowItem><Arrow><ArrowLeft/></Arrow></LeftArrowItem>,
    }


    const handlerActive = (target: any) => {
        const sample = document.querySelectorAll(".clicked");
        for (let i = 0; i < sample.length; i++) {
            sample[i].classList.remove("clicked");
        }
        target.classList.add('clicked')
    }

    const intl = useIntl()
    const [loc, setLoc] = useState(intl.locale)

    let slider

    if (sliderShips.length >= 3) {
        slider = <SliderWrapper>
            <SliderWrapperContent>
                <StyledSlider {...settings} >
                    {sliderShips.map((ship, index) => (

                        <SliderItem
                            onClick={(target) => {
                                setChosenShipIndex(index)
                                handlerActive(target.currentTarget)
                            }}
                            className={index == chosenShipIndex ? "clicked" : ""}
                            key={index}
                        >
                            <Img
                                loading={'lazy'} fluid={ship} alt=" "
                            />
                        </SliderItem>
                    ))}

                </StyledSlider>
            </SliderWrapperContent>
        </SliderWrapper>
    } else if (sliderShips.length === 2) {
        slider = <SliderWrapper>
            <FakeSliderWrapper>
                {sliderShips.map((ship, index) => (
                    <FakeSliderItem
                        onClick={(target) => {
                            setChosenShipIndex(index)
                            handlerActive(target.currentTarget)
                        }}
                        key={index}
                        className={index == chosenShipIndex ? "clicked" : ""}
                    >
                        <FakeSliderImageWrapper>
                            <Img
                                loading={'lazy'} fluid={ship} alt=" "
                            />
                        </FakeSliderImageWrapper>
                    </FakeSliderItem>
                ))}
            </FakeSliderWrapper>
        </SliderWrapper>
    } else {
        slider = <SliderWrapper>
            <FakeSliderWrapper>
                {sliderShips.map((ship, index) => (
                    <FakeSliderItem
                        onClick={(target) => {
                            setChosenShipIndex(index)
                            handlerActive(target.currentTarget)
                        }}
                        className={index == chosenShipIndex ? "clicked" : ""}
                        key={index}
                    >
                        <FakeSliderImageWrapper>
                            <Img
                                loading={'lazy'} fluid={ship} alt=" "
                            />
                        </FakeSliderImageWrapper>
                    </FakeSliderItem>
                ))}
            </FakeSliderWrapper>
        </SliderWrapper>
    }


    return (
        <ShipsWrapper id={'ships'}>
            <ShipsContent>
                <ImageWrapperDesktop>
                    <Img
                        style={{zIndex: -2, width: '100%'}}
                        objectFit="cover"
                        objectPosition="50% 50%"
                        loading={'lazy'} fixed={srcImgDesktop} alt="Hungar"
                    />
                </ImageWrapperDesktop>
                <ImageWrapperMobile>
                    <Img
                        style={{zIndex: -2}}
                        loading={'lazy'} fluid={srcImgMobile} alt="Hungar mobile"
                    />
                </ImageWrapperMobile>
                <ShipsContentWrapper>
                    <PanelAndTexts>
                        <TextWrapper>
                            <Text>
                                <TextHeading>
                                    <FormattedMessage id='ships.headline'/>
                                </TextHeading>
                                <ShipStrokeWrapper>
                                    <ShipStroke/>
                                </ShipStrokeWrapper>
                                <TextParagraph localisation={loc}>
                                    <FormattedMessage id='ships.choosetactics'/>
                                </TextParagraph>
                            </Text>
                        </TextWrapper>
                        <ShipsPanel>
                            <ShipPanelWrapper>
                                <PanelItemWrapper onClick={activeK}>
                                    <ShipButton status={activeKinetic} text={intl.formatMessage({id: "ships.kinetic"})}
                                                type={1}/>
                                </PanelItemWrapper>
                                <PanelItemWrapper onClick={activeL}>
                                    <ShipButton status={activeLaser} text={intl.formatMessage({id: "ships.laser"})}
                                                type={2}/>
                                </PanelItemWrapper>
                                <PanelItemWrapper onClick={activeP}>
                                    <ShipButton status={activePlasma} text={intl.formatMessage({id: "ships.plasma"})}
                                                type={3}/>
                                </PanelItemWrapper>
                            </ShipPanelWrapper>
                        </ShipsPanel>
                    </PanelAndTexts>
                    <MainShipWrapper>
                        <MainShip>
                            <Img
                                style={{zIndex: -2}}
                                loading={'lazy'} fluid={sliderShips[chosenShipIndex]} alt="Chosen Ship"
                            />
                        </MainShip>
                        {slider}
                    </MainShipWrapper>
                </ShipsContentWrapper>
            </ShipsContent>
        </ShipsWrapper>
    )
}