import React, {ReactNode, useEffect, useState} from "react";
import styled from "styled-components";

const SampleNextArrow = styled.div`{
  
  @media(min-width: 1000px){
    width: 35px;
    height: 103px;
  }
  position: relative;
  top: 0;
  left: 0;
  width: 23px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #00D1FF6B;
  background: radial-gradient(86.2% 51.33% at 50.63% 50.44%, rgba(0, 24, 29, 0.2774) 0%, rgba(0, 207, 253, 0.725416) 100%);
  :hover, :focus {
    background: #00CFFDB9;
  }
}`

const IconWrapper = styled.div`{
  width: 8px;
  :hover, :focus {
    color: #271F12;
  }
  @media(min-width: 1000px){
    width: 17px;
  }
  
}`
export interface LayoutProps
    extends React.HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
}


export const Arrow = ({children}: LayoutProps) => {
    return (
        <SampleNextArrow>
            <IconWrapper>
                {children}
            </IconWrapper>
        </SampleNextArrow>
    )
}