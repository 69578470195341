import {StaticImage} from "gatsby-plugin-image";
import Img from "gatsby-image";
import React, {useState} from "react";
import styled from "styled-components";
import {graphql, useStaticQuery} from "gatsby";
import {Phone} from "../phone/Phone";
import {ShipStroke} from "../../../assets/svg/ShipStroke";
import { useIntl, Link, FormattedMessage} from 'gatsby-plugin-intl';


const GameplayContentWrapper = styled.div`{
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
  z-index: 25;
  width: 100%;
  top: 0;
  left: 0;
}`


const AdvertingWrapper = styled.div`{
  width: 100%;
  padding: 16px;
  position: relative;
  max-width: 500px;
  height: 400px;
  @media (min-width: 380px) {
    height: 450px;
  }
  @media (min-width: 720px) {
    height: 500px;
  }
  @media (min-width: 1000px) {
    height: 800px;
  }
}`


const DescriptionWrapper = styled.div`{
  width: 100%;
  text-align: left;
  max-width: 570px;
  padding: 16px;
  position: relative;
}`

const DescriptionHeading = styled.h1`{
  margin-bottom: 0;
  font-size: 26px;
  line-height: 32.1px;
  font-weight: 900;
  color: #FFA116;
  position: relative;
  z-index: 100;
  font-family: Fira Sans Bold;
  @media (min-width: 380px) {
    font-size: 32px;
    line-height: 38.4px;
  }
  @media (min-width: 720px) {
    font-size: 48px;
    line-height: 57.6px;
  }
}`

const DescriptionHeadingWrapper = styled.div` {
  position: relative;
  width: 100%;
  max-width: 570px;
  margin-top: 60px;
  @media (min-width: 1000px) {
    margin-bottom: 12px;
  }
}`

interface LocalProps {
    localisation: string
}


const DescriptionParagraph = styled.p<LocalProps>`{
  font-family: ${({ localisation }) => (localisation === 'ru') ? "Roboto, sans-serif" : 'Courier Prime Regular,sans-serif'};
  color: rgb(182, 167, 119);
  position: relative;
  z-index: 100;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  @media (min-width: 1100px) {
    font-size: 20px;
    line-height: 26.4px;
  }
}`

const DescriptionParagraphWrapper = styled.div` {
  margin-top: 32px;
  width: 100%;
  max-width: 380px;
  @media (min-width: 1000px) {
    max-width: 560px;
    margin-top: 46px ;
  }
}`

const LampsWrapper = styled.div`{
  position: absolute;
  z-index: 5;
  top: -1%;
  left: 0;
  width: 300px;
  @media (min-width: 500px) {
    width: 400px;
    top: -2%;
    left: 0%;
  }
  @media (min-width: 1000px) {
    width: 1000px;
    top: -2%;
    left: -90%;
  }
}`

const ShipStrokeWrapper = styled.div`{
  width: 100%;
  margin: 0;
  padding: 0;
  max-height: 3px;
  overflow-y: hidden ;
}`

export const GameContent = () => {
    const data = useStaticQuery(graphql`
    query {
      mobileLamps: file(relativePath: { eq: "gameplay/lamps/lamps-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 16000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopLamps: file(relativePath: { eq: "gameplay/lamps/lamp.png" }) {
        childImageSharp {
          fluid(maxWidth: 16000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

    const lamps = [
        data.mobileLamps.childImageSharp.fluid,
        {
            ...data.desktopLamps.childImageSharp.fluid,
            media: `(min-width: 720px)`,
        },
    ]

    const intl = useIntl()

    const [loc, setLoc] = useState(intl.locale)

    return (
        <GameplayContentWrapper>
            <AdvertingWrapper>
                <LampsWrapper>
                    <Img
                        style={{zIndex: -2}}
                        loading={'lazy'} fluid={lamps} alt="Lamps Background"
                    />
                </LampsWrapper>
                <Phone/>
            </AdvertingWrapper>
            <DescriptionWrapper>
                <DescriptionHeadingWrapper>
                    <DescriptionHeading>
                        <FormattedMessage id='story.headline1'/>
                        <br/>
                        <FormattedMessage id='story.headline2'/>
                    </DescriptionHeading>
                </DescriptionHeadingWrapper>
                <ShipStrokeWrapper>
                    <ShipStroke/>
                </ShipStrokeWrapper>
                <DescriptionParagraphWrapper>
                    <DescriptionParagraph localisation={loc}>
                        <FormattedMessage id='story.text'/>
                    </DescriptionParagraph>
                </DescriptionParagraphWrapper>
            </DescriptionWrapper>
        </GameplayContentWrapper>
    )
}