import * as React from "react"
import {Layout} from "../components/layout/Layout";
import '../global-css/global.css'
import {AboutWildSpace} from "../components/about-wild-space/AboutWildSpace";
import {Gameplay} from "../components/gameplay/Gameplay";
import {Ships} from "../components/ships/Ships";
import {Heroes} from "../components/heroes/Heroes";
import {useIntl} from "gatsby-plugin-intl"
// @ts-ignore
import {SEO} from "../components/seo/seo.js"


const IndexPage = () => {
    const intl = useIntl()
    return (
        <>
            <SEO
                title="Wild Space"
                href={'wildspace.games/'}
                description={intl.formatMessage({id: "desc"})}
                keys={intl.formatMessage({id: "keys"})}
            />
            <Layout>
                <AboutWildSpace/>
                <Gameplay/>
                <Ships/>
                <Heroes/>
            </Layout>
        </>

    )
}

export default IndexPage
