import React from "react";
import styled from "styled-components";
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
// @ts-ignore
import {useWindowWidth} from '../../hooks/useWidth'
// @ts-ignore
import WildSpaceIcon from "../../svg/wildspace.svg" ;
import {Adverting} from "../adverting/Adverting";



const AboutWrapper = styled.section`{
  width: 100%;
  z-index: 100;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  background: rgba(3, 1, 3, 1);
  @media (min-width: 720px) {
    background: rgba(10, 7, 4, 1);
    box-shadow: inset 0 0 50px 50px rgba(4, 0, 5, 1);
    -moz-box-shadow: inset 0 0 50px 50px rgba(4, 0, 5, 1);
    -webkit-box-shadow: inset 0 0 50px 50px rgba(4, 0, 5, 1);
  }
  @media (min-width: 1100px) {
    background: rgba(3, 1, 3, 1);
    box-shadow: none;
    height: 887px;
  }
}`

const BackgroundImageWrapper = styled.div`{
  position: absolute;
  top: 50px;
  width: 100%;
  max-width: 2040px;
  @media (min-width: 720px) {
    display: none;
  }
  @media (min-width: 1100px) {
    top: 0;
    display: block;
    position: absolute;
    width: 100%;
  }
}`


const BackgroundWrapper = styled.div`{
  width: 100%;
  mask-image: linear-gradient(180deg, #FFFFFF 79.37%, rgba(255, 255, 255, 0) 100%);
  webkit-mask-image: linear-gradient(180deg, #FFFFFF 89.37%, rgba(255, 255, 255, 0) 100%);
}`

const ImageLayer = styled.div`{
  @media(min-width: 440px){
    display: block;
    overflow: hidden;
    width: 100%;
    mask-image: linear-gradient(180deg, #FFFFFF 79.37%, rgba(255, 255, 255, 0) 100%);
    webkit-mask-image: linear-gradient(180deg, #FFFFFF 89.37%, rgba(255, 255, 255, 0) 100%);
  }
  display: none;
}`

const ImageLayerMobile = styled.div`{
 @media(min-width: 440px){
   display: none;
 }
  width: 100%;
}`


const AboutContent = styled.div`{
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  @media (min-width: 1100px) {
    padding-top: 180px;
    flex-direction: row;
    width: 1100px;
  }
}`

const AboutHeroesWrapper = styled.div`{
  position: relative;
  width: 100%;
  top: 100px;
  justify-content: center;
  display: flex;
  @media (min-width: 1100px) {
    width: 520px;
    position: static;
  }

}`

const HeroWrapperLayout = styled.div`{
  width: 100%;
  position: relative;
  max-width: 360px;
  top: 45px;
  
  @media (min-width: 1100px) {
    top: -10px;
    position: relative;
    max-width: 520px;
    width: 520px;
  }
}`

const WildSpace = styled.div`{
  position: relative;
  z-index: 2000;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 9px;
  

  @media (min-width: 350px) {
    margin-bottom: 20px;
  }

  @media (min-width: 1100px) {
    padding: 0;
    justify-content: normal;
    position: static;
    display: flex;
    flex-direction: column;
  }
}`

const WildWrapper = styled.div`{
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1000;
  top: 160px;
  left: 0;
  display: flex;
  width: 195px;
 
  margin-bottom: 5.5px;
  @media (min-width: 720px) {
    top: 0;
    margin-bottom: 36px;
    width: 372px;
  }
}`

const WildIconWrapper = styled.div`{
  width: 100%;
}`


const AdvertingLayoutWrapper = styled.div`{
  width: 100%;
  position: relative;
  top: 65px;
  left: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 85px;
  @media(min-width: 350px){
    bottom: 15px;
  }
  @media (min-width: 1100px) {
    display: none;
  }
}`


const AdvertingLayoutWideWrapper = styled.div`{
  display: none;
  @media (min-width: 1100px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}`


export const AboutWildSpace = () => {

    const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "about/about-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 13750, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopImage: file(relativePath: { eq: "about/about.png" }) {
        childImageSharp {
          fixed(width: 2040, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      pictureMobile: file(relativePath: { eq: "about/about-heroes-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pictureDesktop: file(relativePath: { eq: "about/about-heroes.png" }) {
        childImageSharp {
          fluid(maxWidth: 16000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

    const picture = [
        data.pictureMobile.childImageSharp.fluid,
        {
            ...data.pictureDesktop.childImageSharp.fluid,
            media: `(min-width: 1100px)`,
        },
    ]

    const sourceImagesMobile = data.mobileImage.childImageSharp.fluid
    const sourceImagesDesktop = data.desktopImage.childImageSharp.fixed


    return (
        <AboutWrapper id={'overview'}>
            <BackgroundImageWrapper>
                <BackgroundWrapper>
                    <ImageLayer>
                        <Img
                            style={{zIndex: -2}}
                            objectFit="cover"
                            objectPosition="50% 50%"
                            loading={'lazy'} fixed={sourceImagesDesktop} alt="Wild Space Background"
                        />
                    </ImageLayer>
                    <ImageLayerMobile>
                        <Img
                            style={{zIndex: -2}}
                            loading={'lazy'} fluid={sourceImagesMobile} alt="Wild Space Background"
                        />
                    </ImageLayerMobile>
                </BackgroundWrapper>
            </BackgroundImageWrapper>
            <AboutContent>
                <WildSpace>
                    <WildWrapper>
                        <WildIconWrapper>
                            <WildSpaceIcon/>
                        </WildIconWrapper>
                    </WildWrapper>
                    <AdvertingLayoutWideWrapper>
                        <Adverting/>
                    </AdvertingLayoutWideWrapper>
                </WildSpace>
                <AboutHeroesWrapper>
                    <HeroWrapperLayout>
                        <Img
                            style={{zIndex: -2}}
                            loading={'lazy'} fluid={picture} alt="Wild Space Heroes"
                        />
                    </HeroWrapperLayout>
                </AboutHeroesWrapper>
                <AdvertingLayoutWrapper>
                    <Adverting/>
                </AdvertingLayoutWrapper>
            </AboutContent>
        </AboutWrapper>
    )
}