import React, {useState} from "react";
import styled from "styled-components";
import {ShipStroke} from "../../../assets/svg/ShipStroke";
import {StaticImage} from "gatsby-plugin-image";
import {FormattedMessage} from "gatsby-plugin-intl";

const TextWrapper = styled.div`{
  margin: 0;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}`

const TextInsideWrapper = styled.div`{
  width: 100%;
  max-width: 1480px;
  padding: 100px 16px 25px;
  display: flex;
  justify-content: right;
  margin: 0;
  @media (min-width: 1000px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}`

const Text = styled.div`{
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  @media(min-width: 1000px){
    width: 570px;
  }
}`

const DescriptionHeading = styled.h1`{
  margin: 0;
  padding: 0;
  font-size: 26px;
  font-weight: 900;
  color: #FFA116;
  position: relative;
  z-index: 100;
  font-family: Fira Sans Bold;
  @media (min-width: 380px) {
    font-size: 32px;
  }
  @media (min-width: 720px) {
    font-size: 48px;
  }
}`


const DescriptionHeadingWrapper = styled.div` {
  margin: 0;
  text-align: right;
  width: 100%;
  @media (min-width: 1000px) {
    margin-top: 100px;
  }
}`

const DescriptionParagraph = styled.p`{
  margin: 0;
  font-family: "Fira Sans",sans-serif;
  color: rgb(182, 167, 119);
  position: relative;
  z-index: 100;
  font-size: 12px;
  font-weight: 400;
  @media (min-width: 380px) {
    font-size: 14px;
  }
  @media (min-width: 720px) {
    font-size: 16px;
  }
}`

const DescriptionParagraphWrapper = styled.div` {
  text-align: right;
  margin-top: 12px;
  width: 100%;
}`

const ShipStrokeWrapper = styled.div`{
  transform: rotate(180deg);
  width: 100%;
  margin: 0;
  padding: 0;
  max-height: 3px;
  overflow-y: hidden ;
}`

const BackWrapper = styled.div`{
  //@media (min-width: 1100px){
  //  display: block;
  //  width: 800px;
  //}
  //@media (min-width: 1600px){
  //  width: 1000px;
  //}
  display: none;
  position: absolute;
  top: 25%;
  left: 5%;
  transform: rotate(270%);
  z-index: 10000;
}`



export const HeroesText = () => {
    return (
        <TextWrapper id={'heroes'}>
            <TextInsideWrapper>
                <Text>
                    <BackWrapper>
                        <StaticImage
                            style={{zIndex: -2}}
                            placeholder={'blurred'}
                            src={`../../../images/gameplay/back/space.png`}
                            alt={''}
                            formats={['auto', 'webp', 'avif']}
                            quality={100}
                        >
                        </StaticImage>
                    </BackWrapper>
                    <DescriptionHeadingWrapper>
                        <DescriptionHeading>
                            <FormattedMessage id='heroes.headline'/>
                        </DescriptionHeading>
                    </DescriptionHeadingWrapper>
                    <ShipStrokeWrapper>
                        <ShipStroke/>
                    </ShipStrokeWrapper>
                    <DescriptionParagraphWrapper>
                        <DescriptionParagraph>
                            <FormattedMessage id='heroes.subtitle'/>
                        </DescriptionParagraph>
                    </DescriptionParagraphWrapper>
                </Text>
            </TextInsideWrapper>
        </TextWrapper>
    )
}