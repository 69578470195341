import React from "react";
import styled from "styled-components";
import {BackgroundGameplay} from "./background/BackgroundGameplay";
import {GameContent} from "./game-content/GameContent";
import {graphql, useStaticQuery} from "gatsby";

const GameplayWrapper = styled.section`{
  width: 100%;
  z-index: 100;
  position: relative;
  background: rgb(3.2, 1.8, 1.8);

}`

const GameplayBackLayout = styled.div`{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}`

const GameplayLayout = styled.div`{
  position: relative;
  top: 0;
  left: 0;
  max-width: 1100px;
  width: 100%;
}`

const ScrollContainer = styled.div`{
  position: absolute;
  z-index: -1;
  visibility: hidden;
  top: -100px;
  height: 10px;
}`


export const Gameplay = () => {

    return (
        <GameplayWrapper>
            <ScrollContainer id={'story'} />
            <GameplayBackLayout>
                <GameplayLayout>
                    {/*<BackgroundGameplay/>*/}
                    <GameContent/>
                </GameplayLayout>
            </GameplayBackLayout>
        </GameplayWrapper>
    )
}