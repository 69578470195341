import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
// @ts-ignore
import scrollTo from 'gatsby-plugin-smoothscroll';
import { FormattedMessage, changeLocale, useIntl} from 'gatsby-plugin-intl';
// @ts-ignore
import IconBottom from "../../../svg/iconbottom.svg" ;
// @ts-ignore
import IconTop from "../../../svg/icontop.svg" ;

interface ButtonProps {
    activeBtn: boolean
}

interface LocalProps {
    localisation: string
}



const StyledHeader = styled.header`{
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  height: 50px;
  top: 0;
  z-index: 10000;
  color: rgb(182, 167, 119);
  font-size: 16px;
  font-weight: 400;
  //background: rgba(3, 1, 0, 1.0);
  font-family: 'Courier Prime Regular';
  @media (min-width: 720px) {
    position: fixed;
  }
}`

const StyledHeaderWrapper = styled.div`{
  @media (max-width: 720px) {
    justify-content: right;
    padding-left: 10px;
    padding-right: 10px;
  };
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1600px;
  align-items: flex-end;
  padding-left: 100px;
  padding-right: 50px;
  padding-bottom: 5px;
}`


const NavSection = styled.nav`{
  display: none;
  @media (min-width: 720px) {
    justify-content: space-between;
    display: flex;
    width: 440px;
  };
}`

const NavLink = styled.div`{
  margin: 0;
  z-index: 1000;
}`


const LocButton = styled.button`{
  display: flex;
  border: none;
  align-items: center;
  background: none;
  position: relative;
  @media(max-width: 420px){
    margin-bottom: 5px;
  }
}`

const LocText = styled.div<LocalProps>`{
  font-size: 16px;
  height: 18px;
  font-family: ${({ localisation }) => (localisation === 'ru') ? "Roboto, sans-serif" : "Courier Prime Regular, sans-serif"};
  color: rgb(182, 167, 119);
  z-index: 1000;
  :hover {
    color: #00D1FF;
    cursor: pointer;
  }
  
  @media(min-width: 1100px){
    font-size: 20px;
    height: 22px;
  }
}`

const ButtonText = styled.div<LocalProps>`{
  border-bottom: 1px solid #F4DDA0;
  font-size: 16px;
  position: relative;
  height: 18px;
  font-family: ${({ localisation }) => (localisation === 'ru') ? "Roboto, sans-serif" : 'Courier Prime Regular,sans-serif'};
  color: rgb(182, 167, 119);
  z-index: 1000;

  :hover {
    //color: #00D1FF;
    //border-bottom: 1px solid #00D1FF;
    cursor: pointer;
  }
  @media(min-width: 1100px){
    font-size: 20px;
    height: 22px;
  }
}`

const BackgroundImage = styled.div`{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}`

const ButtonWrapper = styled.div`{
  width: 24px;
  z-index: 2000;
  margin: 0;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 1px;
}`

const DropDownWrapper = styled.div<ButtonProps>`{
  margin: auto;
  z-index: 2000;
  position: absolute;
  top: 30px;
  left: -17px;
  border-radius: 8px;
  border: 1px solid #00D1FF;
  width: 117px;
  height: 89px;
  background: #271F12;
  display: ${({activeBtn}) => activeBtn ? 'block' : 'none'};
  @media(min-width: 1100px){
    left: -10px;
  }
}`

const DropDownContent = styled.div`{
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
}`

const LinkButtonRu = styled.div<LocalProps>`{
  width: 100%;
  background: ${({ localisation }) => (localisation === 'ru') ? 'rgba(72, 61, 32, 0.5)' : 'none'};
  border-radius: 7px;
  text-align: left;
  height: 39px;
  padding: 8px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #00D1FF;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}`


const LinkButtonEn = styled.div<LocalProps>`{
  width: 100%;
  background: ${({ localisation }) => (localisation === 'en') ? 'rgba(72, 61, 32, 0.5)' : 'none'};
  border-radius: 7px;
  text-align: left;
  display: flex;
  align-items: center;
  height: 39px;
  padding: 8px;
  font-size: 16px;
  color: #00D1FF;
  font-weight: 700;
  font-family: "Courier Prime Regular";
}`


export const Header = () => {


    const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "header/header-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 4080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopImage: file(relativePath: { eq: "header/header.png" }) {
        childImageSharp {
          fluid(maxWidth: 4080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      buttonBack: file(relativePath: { eq: "header/dropdown.png" }) {
        childImageSharp {
          fluid(maxWidth: 4080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

    const backImages = [
        data.mobileImage.childImageSharp.fluid,
        {
            ...data.desktopImage.childImageSharp.fluid,
            media: `(min-width: 720px)`,
        },
    ]

    const dropDownBack = [
        data.buttonBack.childImageSharp.fluid,
    ]


    const handler = () => {
        if (active) {
            setBtn(<IconBottom/>)
            setActive(false)
        } else {
            setBtn(<IconTop/>)
            setActive(true)
        }
    }

    const intl = useIntl()

    const [loc, setLoc] = useState(intl.locale)

    const [active, setActive] = useState(false)
    const [btn, setBtn] = useState(<IconBottom/>)

    return (
        <StyledHeader>
            <StyledHeaderWrapper>
                <NavSection>
                    <NavLink>
                        <LocText onClick={() => scrollTo('#overview')} localisation={loc}>
                            <FormattedMessage id='header.overview'/>
                        </LocText>
                    </NavLink>
                    <NavLink>
                        <LocText onClick={() => scrollTo('#story')} localisation={loc}>
                            <FormattedMessage id='header.story'/>
                        </LocText>
                    </NavLink>
                    <NavLink>
                        <LocText onClick={() => scrollTo('#ships')} localisation={loc}>
                            <FormattedMessage id='header.ships'/>
                        </LocText>
                    </NavLink>
                    <NavLink>
                        <LocText onClick={() => scrollTo('#heroes')} localisation={loc}>
                            <FormattedMessage id='header.heroes'/>
                        </LocText>
                    </NavLink>
                </NavSection>
                <LocButton
                    onClick={() => {
                        handler()
                    }}
                >
                    <ButtonText localisation={loc}>
                        <FormattedMessage id='lang'/>
                    </ButtonText>
                    <ButtonWrapper>
                        {btn}
                    </ButtonWrapper>
                    <DropDownWrapper activeBtn={active}>
                            <Img
                                style={{zIndex: 3000}}
                                loading={'lazy'} fluid={dropDownBack} alt=" "
                            />
                        <DropDownContent style={{zIndex: 4000}}>
                            <LinkButtonEn localisation={loc} onClick={() => changeLocale("en")} >
                                English
                            </LinkButtonEn>
                            <LinkButtonRu localisation={loc} onClick={() => changeLocale("ru")} >
                                Русский
                            </LinkButtonRu>
                        </DropDownContent>
                    </DropDownWrapper>
                </LocButton>
            </StyledHeaderWrapper>
            <BackgroundImage>
                <Img
                    style={{zIndex: 300}}
                    loading={'lazy'} fluid={backImages} alt=" "
                />
            </BackgroundImage>
        </StyledHeader>
    )
}