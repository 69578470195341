import React from "react"
import { useSiteMetadata } from "../../hooks/useSiteMetadata"
import {Helmet} from "react-helmet";

export const SEO = ({ title, description, pathname, href, keys, haveArticle,articleBody, articleDescription, children }) => {
    const { title: defaultTitle, description: defaultDescription, siteUrl, } = useSiteMetadata()

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        url: `${siteUrl}${pathname || ``}`,
        href: `https://${href}`,
        keys: `${keys}`,
        articleBody: `${articleBody}`,
        articleDescription: `${articleDescription}`

    }

    const schemaOrgJSONLD = [
        {
            "@context": "http://schema.org",
            "@type": "WebSite",
            url: seo.url,
            name: seo.title,
        },
        {
            "@context": "https://schema.org",
            "@type": "Place",
            address: "",
        }
    ]
    if (haveArticle === true) {
        schemaOrgJSONLD.push({
            "@context": "http://schema.org",
            "@type": "Article",
            author: {
                "@type": "Person",
                knowsAbout: ["Games", "MobileGames"],
            },
            description: seo.articleDescription,
            articleBody: seo.articleBody,
        })
    }

    return (
        <Helmet>
            <title>{seo.title}</title>
            <link rel="canonical" href={seo.href}/>
            <meta name="viewport" content="width=device-width"/>
            <meta property={'og:description'} name="description" content={seo.description} />
            <meta name="keywords" content={seo.keys} />
            <meta name="twitter:title" content={seo.title} />
            <script type="application/ld+json">
                {JSON.stringify(schemaOrgJSONLD)}
            </script>
            {children}
        </Helmet>
    )
}