import React, {useEffect, useState} from "react";
import styled from "styled-components";

// @ts-ignore
import Plasma from "../../../svg/plasma.svg" ;
// @ts-ignore
import Kinetic from "../../../svg/kinetic.svg" ;
// @ts-ignore
import Laser from "../../../svg/laser.svg" ;
import {useIntl} from "gatsby-plugin-intl";


const SVG = styled.img`{
}`


interface ButtonProps {
    active: boolean
}

interface LocalProps {
    localisation: string
}


const ButtonWrapper = styled.button<ButtonProps>`{
  border: ${({active}) => active ? '2px solid #00D1FF' : '1px solid #00D1FF'};
  width: ${({active}) => active ? '100px' : '98px'};
  height: ${({active}) => active ? '30px' : '28px'};
  display: flex;
  position: relative;
  z-index: 10000;
  align-items: center;
  background: none;
  
  :hover {
    background: linear-gradient(0deg, rgba(0, 209, 255, 0.38), rgba(0, 209, 255, 0.38)) ;
  }

  @media(min-width: 380px){
    width: ${({active}) => active ? '120px' : '118px'};
    height: ${({active}) => active ? '34px' : '32px'};
  }
  
  @media (min-width: 1000px) {
    width: ${({active}) => active ? '292px' : '290px'};
    height: ${({active}) => active ? '49px' : '47px'};
    padding: 10px;
  }
}`

const ButtonActiveWrapper = styled.div<ButtonProps>`{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 104px;
  height: 34px;
  border: ${({active}) => active ? '1px solid rgba(244, 221, 160, 0.37)' : 'none'};
  background: ${({active}) => active ? 'linear-gradient(0deg, rgba(0, 209, 255, 0.38), rgba(0, 209, 255, 0.38))' : 'none'};

  @media(min-width: 380px){
    width: 124px;
    height: 38px;
  }
  
  @media (min-width: 1000px) {
    width: 300px;
    height: 57px;
  }
}`

const IconWrapper = styled.div`{
  width: 15px;
  margin-left: 9px;
  @media (min-width: 1000px) {
    width: 23px;
  }
}`

const AdditionalIconWrapper = styled.div`{
  width: 100%;
}`

const TextWrapper = styled.div<LocalProps>`{
  margin-left: 5px;
  margin-top: 3px;
  font-size: 14px;
  color: #F4DDA0;
  font-weight: 700;
  font-family: ${({localisation}) => (localisation === 'ru') ? "Roboto, sans-serif" : 'Courier Prime Regular,sans-serif'};
  @media (min-width: 1000px) {
    margin-left: 20px;
    font-size: 18px;
  }
}`


interface ShipButtonProps {
    status: boolean,
    text: string,
    type: number,
}


export const ShipButton = ({status, text, type}: ShipButtonProps) => {

    const intl = useIntl()
    const [loc, setLoc] = useState(intl.locale)

    let icon

    switch (type) {
        case 1 :
            icon = <Kinetic/>
            break;
        case 2 :
            icon = <Laser/>
            break;
        case 3 :
            icon = <Plasma/>
            break;
        default:
            icon = <></>
    }
    let btn = <ButtonActiveWrapper active={status}><ButtonWrapper active={status}>
        <IconWrapper>
            <AdditionalIconWrapper>
                {icon}
            </AdditionalIconWrapper>
        </IconWrapper>
        <TextWrapper localisation={loc}>{text}</TextWrapper>
    </ButtonWrapper>
    </ButtonActiveWrapper>

    return (
        <>{btn}</>
    )
}