import {StaticImage} from "gatsby-plugin-image";
import Img from "gatsby-image";
import React, {useState} from "react";
import styled from "styled-components";
import {Hero} from "../hero/Hero";
import {graphql, useStaticQuery} from "gatsby";
import {useDisclosure} from "@chakra-ui/react";
import {Box} from "@chakra-ui/react"
// @ts-ignore
import '../phone/phone.css'

import {
    Modal,
    ModalOverlay,
    ModalContent,
} from '@chakra-ui/react'

const PhoneWrapper = styled.div`{
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
  width: 170px;
  @media (min-width: 380px) {
    width: 200px;
    top: 13px;
    left: 13px;
  }
  @media (min-width: 720px) {
    width: 230px;
    left: 15px;
  }
  @media (min-width: 1000px) {
    width: 320px;
    top: 18px;
    left: 20px;
  }
}`

const PhoneBackWrapper = styled.div`{
  position: relative;
  top: 0;
  left: 0;
  z-index: 5;
  width: 190px;
  @media (min-width: 380px) {
    width: 225px;
  }
  @media (min-width: 720px) {
    width: 260px;
  }
  @media (min-width: 1000px) {
    width: 360px;
  }
}`

const BtnPowerWrapper = styled.div`{
  position: absolute;
  z-index: 20;
  top: 20%;
  left: 79%;
  width: 10px;
  @media (min-width: 1000px) {
    width: 15px;
  }
}`
const BtnSilenceWrapper = styled.div`{
  position: absolute;
  z-index: 20;
  top: 20%;
  left: 4%;
  width: 6px;
  @media (min-width: 1000px) {
    width: 8px;
  }
}`


const BtnPlusWrapper = styled.div`{
  position: absolute;
  z-index: 20;
  top: 30%;
  left: 7%;
  width: 8px;
  @media (min-width: 1000px) {
    width: 12px;
  }
}`

const BtnMinusWrapper = styled.div`{
  position: absolute;
  z-index: 21;
  top: 42%;
  left: 10%;
  width: 8px;
  @media (min-width: 1000px) {
    width: 12px;
  }
}`
const IconWrapper = styled.div`{
  cursor: pointer;
  width: 52px;
  top: 40%;
  left: 38%;
  position: absolute;
  @media (min-width: 450px) {
    top: 44%;
    left: 37%;
  }
  z-index: 1000;
  @media (min-width: 1000px) {
    width: 95px;
  }
}`


export const Phone = () => {

    const data = useStaticQuery(graphql`
    query {
      btn: file(relativePath: { eq: "gameplay/phone/btn.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      btnClicked: file(relativePath: { eq: "gameplay/phone/btn-clicked.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

    const btn = [
        data.btn.childImageSharp.fluid,
        data.btnClicked.childImageSharp.fluid,
    ]

    const [button, setButton] = useState(0)

    const {isOpen, onOpen, onClose} = useDisclosure()

    return (
        <PhoneBackWrapper>
            <StaticImage
                style={{zIndex: -2}}
                placeholder={'blurred'}
                src={`../../../images/gameplay/phone/phone-back.png`}
                alt={''}
                formats={['auto', 'webp', 'avif']}
                quality={100}
            >
            </StaticImage>
            <IconWrapper
                onMouseOver={() => {
                    setButton(1)
                }}
                onMouseOut={() => {
                    setButton(0)
                }}
                onClick={onOpen}
            >
                <Img
                    style={{zIndex: -2}}
                    loading={'lazy'} fluid={btn[button]} alt=""
                />
            </IconWrapper>
            <Box position={'absolute'}>
                <Modal isOpen={isOpen} onClose={onClose} isCentered
                >
                    <ModalOverlay style={{opacity: "25%"}}
                                  backdropFilter='auto'
                                  backdropBlur='2px'/>
                    <ModalContent style={{
                        background: 'black',
                        width: '0px',
                        zIndex: 10000,
                    }}>
                        <iframe className={'frame'} src="https://www.youtube.com/embed/Y0DVS07oZNI"
                                title="Wild Space gameplay video"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                    </ModalContent>
                </Modal>
            </Box>
            <BtnPowerWrapper>
                <StaticImage
                    style={{zIndex: -2}}
                    placeholder={'blurred'}
                    src={`../../../images/gameplay/phone/power-btn.png`}
                    alt={''}
                    formats={['auto', 'webp', 'avif']}
                    quality={100}
                >
                </StaticImage>
            </BtnPowerWrapper>
            <BtnSilenceWrapper>
                <StaticImage
                    style={{zIndex: -2}}
                    placeholder={'blurred'}
                    src={`../../../images/gameplay/phone/silence-btn.png`}
                    alt={''}
                    formats={['auto', 'webp', 'avif']}
                    quality={100}
                >
                </StaticImage>
            </BtnSilenceWrapper>
            <BtnPlusWrapper>
                <StaticImage
                    style={{zIndex: -2}}
                    placeholder={'blurred'}
                    src={`../../../images/gameplay/phone/btn-plus.png`}
                    alt={''}
                    formats={['auto', 'webp', 'avif']}
                    quality={100}
                >
                </StaticImage>
            </BtnPlusWrapper>
            <BtnMinusWrapper>
                <StaticImage
                    style={{zIndex: -2}}
                    placeholder={'blurred'}
                    src={`../../../images/gameplay/phone/btn-minus.png`}
                    alt={''}
                    formats={['auto', 'webp', 'avif']}
                    quality={100}
                >
                </StaticImage>
            </BtnMinusWrapper>
            <PhoneWrapper>
                <StaticImage
                    style={{zIndex: -2}}
                    placeholder={'blurred'}
                    src={`../../../images/gameplay/phone/gameplay-phone.png`}
                    alt={'Phone'}
                    formats={['auto', 'webp', 'avif']}
                    quality={100}
                >
                </StaticImage>
                <Hero/>
            </PhoneWrapper>
        </PhoneBackWrapper>
    )
}