import React from "react";
import {Apple} from "../svg/icons/Apple";
import {SvgButton} from "./SvgButton";


export const AppStore = () => {
    return (
        <a href="https://apps.apple.com/ru/app/wild-space/id1624020103" target="_blank" rel="external">
            <SvgButton>
                <Apple/>
            </SvgButton>
        </a>
    )
}