import React from "react";
import {AppStore} from "../../assets/buttons/AppStore";
import styled from "styled-components";
import {GooglePLay} from "../../assets/buttons/GooglePlay";
import {RuStore} from "../../assets/buttons/RuStore";


const SpaceIconWrapper = styled.div`{
  display: flex;
  width: 310px;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: 720px) {
    width: 380px;
  }
}`
const IconsRowWrapper = styled.div`{
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
}`

const CenterIconsRowWrapper = styled.div`{
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
}`

export const Adverting = () => {
    return (
        <SpaceIconWrapper>
            <IconsRowWrapper>
                <AppStore/>
                <GooglePLay/>
            </IconsRowWrapper>
            <CenterIconsRowWrapper>
                <RuStore/>
            </CenterIconsRowWrapper>
        </SpaceIconWrapper>
    )
}