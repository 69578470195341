import React from "react";
import {RuStoreIcon} from "../svg/icons/RuStoreIcon";
import styled from "styled-components";
import {SvgButton} from "./SvgButton";

const RuStoreWrapper = styled.div`{
  width: 170px;
  cursor: pointer;

    display: flex;
  @media (min-width: 720px) {
    width: 170px;
  }
}`

export const RuStore = () => {
    return (
        <a href="https://apps.rustore.ru/app/com.Wildspace.Wildspacegame" target="_blank" rel="external">
            <SvgButton>
                <RuStoreIcon/>
            </SvgButton>
        </a>
    )
}